import { AutoGraph } from "@mui/icons-material";
import { Container, Skeleton, Stack, Typography, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { DataGrid, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import axios from "axios";
import { findEndpointById } from "../../../Client/Endpoints/endpoints";
import { SERVICE_ID } from "../../../Constants/ui.constants";
import { ErrorOverlay } from "../../Misc/ErrorOverlay";
import { partnerCols } from "../../Misc/Grid";
import { useUser } from "../../../Hooks/Auth/useUser";
import { usePartnerQuery, PartnerResponseWrapped, PartnerReponse } from "../../../Client/Reporting/partner.reporting.client"; // Import necessary types

interface Option {
  id: string;
  name: string;
}

export const renderErrorOverlay = () => {
  return <ErrorOverlay message={"No data"} />;
};

export const PartnerUsagePage: React.FC = () => {
  const associationId = useUser()!.associationId!;
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [errors, setErrors] = useState<Error[]>([]);
  
    // Handle dropdown change
  const handleChange = (event: SelectChangeEvent<string>) => {
  setSelectedOption(event.target.value);
};

  // Fetch dropdown options only once when component mounts
  useEffect(() => {
    const endpoint = findEndpointById("partnerDropdown")!;
    const url = new URL(endpoint);
    url.searchParams.append('providerId', associationId);

    axios.get(url.toString(), {
      withCredentials: true,
      headers: {
        S: SERVICE_ID
      }
    })
    .then(response => {
      if (response.data && Array.isArray(response.data.data)) {
        setOptions(response.data.data); // Set dropdown options
      } else {
        console.error('Expected an array under response.data.data but got:', response.data);
      }
    })
    .catch(error => {
      console.error('Error fetching options:', error);
      setErrors([error]); // Capture errors if fetching options fails
    });
  }, [associationId]);

   const { data, isLoading, error } = usePartnerQuery(selectedOption);


  useEffect(() => {
    if (data) {
      const partnerData = (data as PartnerResponseWrapped).data;

      const rows: GridRowsProp = partnerData.data.map((partner: PartnerReponse) => ({
        id: partner.id,
        yearmonth: partner.yearmonth.toString(),
        searches: partner.searches,
        questionviews: partner.questionviews,
      }));
      setRows(rows);
    } else if (!selectedOption.trim()) {
      setRows([]);
    }

    if (error) {
      setErrors([error]);
    }
  }, [data, error, selectedOption]);




  return (
    <Container>
      <Stack direction="column" alignItems={"flex-start"} spacing={2}>
        <Stack direction="row" alignItems={"center"} spacing={1}>
          <AutoGraph color="primary" />
          <Typography variant="h6" color="primary" gutterBottom>
            Partner Search Usage Data
          </Typography>
        </Stack>

        {/* Conditionally render message or dropdown */}
        {options.length === 0 ? (
          <Typography>
            Your organization does not subscribe to the Roper Center Partner
            Search service.
          </Typography>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="dropdown-label">Select Partner Search portal</InputLabel>
            <Select
              label="Select Partner Search portal"
              id="dropdown"
              value={selectedOption}
              onChange={handleChange}
              displayEmpty
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Skeleton loader while data is loading */}
        {isLoading && <Skeleton sx={{ width: "100%", height: "100%" }} />}

        {/* DataGrid to show table data */}
        {!isLoading && rows.length > 0 && !error && (
          <div style={{ height: "400px", width: "100%" }}>
            <DataGrid
              sx={{
                width: "100%",
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                '& .MuiDataGrid-cell:hover': {
                  color: 'primary.main',
                }
              }}
              rows={rows}
              columns={partnerCols}
              getRowId={(row) => row.id}
              slots={{
                noRowsOverlay: renderErrorOverlay,
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true },
                },
              }}
            />
            <Typography sx={{ mt: 1 }}>Note: individual Question View usage metric data is not available for time periods prior to November 2023.</Typography>
          </div>
        )}

        {/* Error overlay if any errors occurred */}
        {errors.length > 0 && <ErrorOverlay message={"Failed to load data"} />}
      </Stack>
    </Container>
  );
};
