import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { findEndpointById } from "../Endpoints/endpoints";
import { SERVICE_ID } from "../../Constants/ui.constants";
export type PartnerReponse = {
	id:string,
	yearmonth:Date,
    searches:number,
    questionviews:number
}
export type PartnerResponseWrapped = {data:{data: PartnerReponse[]}}
export async function fetchPartner(selectedOption:string): Promise<PartnerResponseWrapped>{
    const endpoint = findEndpointById("partner")!
    var url = new URL(endpoint)   
    url.searchParams.append('providerId', selectedOption)
    return axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}

export const usePartnerQuery = (selectedOption:string) => {
    return useQuery({
      queryKey: ['filterPartnerData', selectedOption],
      queryFn: () => fetchPartner(selectedOption),
      enabled: !!selectedOption.trim(),
      staleTime: 24 * 60 * 60 * 1000, 
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false, 
    })
}